import { takeLatest, all, call, put } from 'redux-saga/effects';
import {
    labTestsByStatus,
    labTestResultEnteredResultFinalized,
    labTestReportSampleLog,
    labTestsDetailByTestRequestId,
    labTestStatusUpdate,
    addUpdateLabTest,
    dashboards,
    expiredlabTests,
    archivedlabTests,
    updateArchiveStatus,
    labTestFilter,
    labTestDisposalFilter
} from '../../../utils/apis/lab-test';

// import { testReqStatusUpdate } from 'utils/apis/testRequest';
import { addNonConfermanceForm } from 'utils/apis/non-confermance';
import {
    startLabTestDataByFilter,
    startLabTestDetailsDataByFilter,
    startLabTestsList,
    startResultEnteredOrGeneratedLabTestList,
    setLabTestsList,
    startLabTestDetailByTestRequestId,
    setLabTestDetail,
    startLabTestDetailsByLabTesttId,
    setLabTestDetails,
    startAddUpdateLabTest,
    startLabSampleLogReport,
    setLabSampleLog,
    startValidationCheck,
    startLabTestStatusUpdate,
    startAddNonConfermance,
    completedAction,
    startAddLabTestDetailsResult,
    startDashboardsData,
    setDashboardsData,
    startArchiveAndDisposalAddData,
    startArchivedDataByLabId,
    setArchivedData,
    startArchivedExpiredLabTestSample,
    startLabTestsScheduledList,
    startResultEntryInBatchGoupedByAnalysis
} from './lab';
import { addSpecimenValidation } from 'utils/apis/validate-specimen';
import {
    labTestByLabTestIdAndReqTest,
    addlabDetailsResultEnter,
    afterValidationLabListByStatus,
    afterValidationLabListByStatusGroupByAnalysis,
    dynamicFilter,
    scheduledTestByAnalysisType,
    enterResultEntryBatch
} from 'utils/apis/lab-test-details';
import { openSnackbar } from '../snackbar';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';
import { archiveDisposalAdd, archiveDisposalByLabId, archiveDisposalById } from 'utils/apis/archive-disposal';
import {
    startAllTestRequest,
    startTestRequestDataByFilter,
    startUpdateTestRequestData,
    completedAction as completedActionTestReq
} from '../testRequest/testRequests';
import { customerDashboard } from 'utils/apis/customer-portal';

// Lab Tests By status or Finalised List  Response (1)
function* labTestsListResponseHandler({ payload }) {
    try {
        const response = yield call(labTestsByStatus, payload);

        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Lab Test List  (1)
function* startUnvalidListhWatcher() {
    yield takeLatest(startLabTestsList.type, labTestsListResponseHandler);
}

// Lab Test Detail  Response (2)
function* labTestDetailsResponseHandler({ payload }) {
    try {
        const response = yield call(labTestsDetailByTestRequestId, payload);
        yield put(setLabTestDetail(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Lab Test Details  (2)
function* startLabTestDetailByTestReqIdhWatcher() {
    yield takeLatest(startLabTestDetailByTestRequestId.type, labTestDetailsResponseHandler);
}
// Lab Test Status Update  Response (3)
function* labTestStatusUpdateResponseHandler({ payload }) {
    try {
        yield call(labTestStatusUpdate, payload);
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Lab Test status Update  (3)
function* startLabTestStatusUpdatehWatcher() {
    yield takeLatest(startLabTestStatusUpdate.type, labTestStatusUpdateResponseHandler);
}
// Lab Test Add/Edit  Response (4)
function* labTestAddEditResponseHandler({ payload }) {
    const { data, isItnew, labStatus, needColumnFilter, columnFilterName } = payload;
    try {
        if (isItnew) {
            yield call(addUpdateLabTest, data);
            yield put(
                startTestRequestDataByFilter({
                    labStatus,
                    needColumnFilter,
                    columnFilterName
                })
            );
            yield put(
                openSnackbar({
                    open: true,
                    message: 'New Request Accepted',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false,
                    actionButton: false
                })
            );
            yield put(completedActionTestReq());
        }
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedActionTestReq());
    }
}
//  Lab Test Add/Edit   (4)
function* startLabTestAddEditWatcher() {
    yield takeLatest(startAddUpdateLabTest.type, labTestAddEditResponseHandler);
}
// Lab Tests Report Sample Log  Response (5)
function* labReportSampleLogListResponseHandler({ payload }) {
    try {
        const response = yield call(labTestReportSampleLog, payload);
        yield put(setLabSampleLog(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Lab Test List Report Sample Log   (5)
function* startReportSampleLogListhWatcher() {
    yield takeLatest(startLabSampleLogReport.type, labReportSampleLogListResponseHandler);
}

// Lab Tests Lists Ready To Archive Or Expired on Current date  Response (6)
function* archivedexpiredlabTestsListResponseHandler({ payload }) {
    const { expiredList } = payload;
    //  alert(expiredList);
    try {
        let response;
        if (expiredList) {
            response = yield call(expiredlabTests, payload);
        } else {
            response = yield call(archivedlabTests, payload);
        }
        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Lab Test List Expired on Current date  (6)
function* startArchivedExpiredListhWatcher() {
    yield takeLatest(startArchivedExpiredLabTestSample.type, archivedexpiredlabTestsListResponseHandler);
}

// Lab Test Data by Filter (7)
function* labtestDataByFilterResponseHandler({ payload }) {
    const { disposalFilter } = payload;
    try {
        let response;
        if (disposalFilter) {
            response = yield call(labTestDisposalFilter, payload);
        } else {
            response = yield call(labTestFilter, payload);
        }
        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Lab Test  Data by Filter  Watcher (7)
function* startLabTestDataByFilterdWatcher() {
    yield takeLatest(startLabTestDataByFilter.type, labtestDataByFilterResponseHandler);
}

// Lab Tests Result Entered and  Finalised List  Response (8)
function* resultEnteredOrGeneratedLabTestListResponseHandler() {
    try {
        const response = yield call(labTestResultEnteredResultFinalized);

        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Lab Tests Result Entered and  Finalised List   (8)
function* startResultEnteredOrGeneratedLabTestListWatcher() {
    yield takeLatest(startResultEnteredOrGeneratedLabTestList.type, resultEnteredOrGeneratedLabTestListResponseHandler);
}

// ////////////////////////////////////////////////////// Validation and lab_test_detail

// Validation Specimen  Response (1)
function* validateSpecimenRponseHandler({ payload }) {
    const { submitData, listData } = payload;
    try {
        yield call(addSpecimenValidation, submitData);
        yield put(startLabTestsList({ ...listData }));
        yield put(
            openSnackbar({
                open: true,
                message: 'Sample Validated',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
        //   yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Validation Specimen  Watcher  (1)
function* startValidateSpecimenWatcher() {
    yield takeLatest(startValidationCheck.type, validateSpecimenRponseHandler);
}
// ////////////////////////////////////////////////////// Lab Details Table tbl_lab_test_detail

// Lab Test details for scheduled test result entry Response(1)
function* labDetailsByLabTestIdRponseHandler({ payload }) {
    try {
        const { groupedTests } = payload;
        let response;
        if (groupedTests) {
            response = yield call(scheduledTestByAnalysisType, payload);
        } else {
            response = yield call(labTestByLabTestIdAndReqTest, payload);
        }

        yield put(setLabTestDetails(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Lab Test details for scheduled test result entry  Watcher  (1)
function* startLabTestDetailsByLabTesttIdnWatcher() {
    yield takeLatest(startLabTestDetailsByLabTesttId.type, labDetailsByLabTestIdRponseHandler);
}
//
// Lab Test Details Result Entry   Response (2)
function* labTestDetailsResultEntryResponseHandler({ payload }) {
    const { labDetails, testReqData, commonData } = payload;
    try {
        yield call(addlabDetailsResultEnter, { labDetails, commonData, testReqData });
        yield put(setFormNavigate(true));
        // if (testReqData) {
        //     yield put(startUpdateTestRequestData(testReqData));
        // }
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Result Entered Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Lab Test Details Result Entry  Watcher  (2)
function* startLabTestDetailsResultEntryWatcher() {
    yield takeLatest(startAddLabTestDetailsResult.type, labTestDetailsResultEntryResponseHandler);
}
// After ValidationList Response (3)
function* afterValidationLabTestsListResponseHandler({ payload }) {
    try {
        let response = [];
        // eslint-disable-next-line eqeqeq
        response = yield call(afterValidationLabListByStatus, payload);
        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Scheduled List with Test Type Watcher  (3)
function* startLabTestScheduledListWatcher() {
    yield takeLatest(startLabTestsScheduledList.type, afterValidationLabTestsListResponseHandler);
}

// Lab Test Details Data by Filter (4)
function* labtestDetailsDataByFilterResponseHandler({ payload }) {
    try {
        const response = yield call(dynamicFilter, payload);
        yield put(setLabTestsList(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Lab Test Details Data by Filter  Watcher (4)
function* startLabTestDetailsDataByFilterdWatcher() {
    yield takeLatest(startLabTestDetailsDataByFilter.type, labtestDetailsDataByFilterResponseHandler);
}
//

//  Test Details Result Entry Batch   Response (5)
function* testDetailsResultEntryInBatchGoupedByAnalysisResponseHandler({ payload }) {
    try {
        yield call(enterResultEntryBatch, payload);
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Result Entered Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Test Details Result Entry Batch   Watcher  (5)
function* startTestDetailsResultEntryBatchWatcher() {
    yield takeLatest(startResultEntryInBatchGoupedByAnalysis.type, testDetailsResultEntryInBatchGoupedByAnalysisResponseHandler);
}

// //////////////////////////////////////////////////////  nonConfermance Form Table tbl_non_confermance
// nonConfermance sample  Response (1)
function* NonConfermanceAddRponseHandler({ payload }) {
    const { conformence, validationData } = payload;
    try {
        if (validationData?.lab_test_id) {
            yield call(addNonConfermanceForm, { conformence, validationData });
        }

        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: validationData?.lab_test_id ? 'Non-Conformance Raised Successfully' : 'Non-Conformance Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   nonConfermance sample  Watcher  (1)
function* startNonConfermanceAddWatcher() {
    yield takeLatest(startAddNonConfermance.type, NonConfermanceAddRponseHandler);
}
// ///////////////////////////////////////////////////// Dashboard Data ///////////
// Dashboard   Response (1)
function* DashboardRponseHandler() {
    try {
        const response = yield call(dashboards);
        yield put(setDashboardsData(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );

        yield put(completedAction());
    }
}
//   Dashboard  Watcher  (1)
function* startDashboardWatcher() {
    yield takeLatest(startDashboardsData.type, DashboardRponseHandler);
}
// /////////////////////////////////////////////////////// Archival And Disposal //////////////
// Archival And Disposal Add  Response (1)
function* archivalAndDisposalRponseHandler({ payload }) {
    try {
        const { labUpdateData, archiveData, updateLabData, listData } = payload;
        yield call(archiveDisposalAdd, { archiveData, labUpdateData, updateLabData });
        let response;
        if (updateLabData) {
            // yield call(addUpdateLabTest, labUpdateData);
            response = yield call(archivedlabTests);
        } else {
            yield call(updateArchiveStatus, { id: archiveData?.lab_test_id, archived_status: 2 });
            response = yield call(expiredlabTests, listData);
        }
        yield put(setLabTestsList(response));
        yield put(
            openSnackbar({
                open: true,
                message: updateLabData ? 'Archived Successfully' : 'Disposed Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Archive And Disposal Add/Edit  Watcher  (1)
function* startArchiveAndDisposalWatcher() {
    yield takeLatest(startArchiveAndDisposalAddData.type, archivalAndDisposalRponseHandler);
}

// Archive Data By Lab Id Response (2)
function* ArchiveDataRponseHandler({ payload }) {
    try {
        const response = yield call(archiveDisposalByLabId, payload);
        yield put(setArchivedData(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//   Archive Data By Lab Id  Watcher  (2)
function* startArchivedDataByLabIddWatcher() {
    yield takeLatest(startArchivedDataByLabId.type, ArchiveDataRponseHandler);
}

// Accumulator
export default function* labTestDataSaga() {
    yield all([
        call(startResultEnteredOrGeneratedLabTestListWatcher),
        call(startLabTestDataByFilterdWatcher),
        call(startLabTestDetailByTestReqIdhWatcher),
        call(startUnvalidListhWatcher),
        call(startLabTestAddEditWatcher),
        call(startValidateSpecimenWatcher),
        call(startLabTestStatusUpdatehWatcher),
        call(startLabTestDetailsByLabTesttIdnWatcher),
        call(startNonConfermanceAddWatcher),
        call(startLabTestDetailsResultEntryWatcher),
        call(startReportSampleLogListhWatcher),
        call(startDashboardWatcher),
        call(startArchiveAndDisposalWatcher),
        call(startArchivedDataByLabIddWatcher),
        call(startArchivedExpiredListhWatcher),
        call(startLabTestScheduledListWatcher),
        call(startLabTestDetailsDataByFilterdWatcher),
        call(startTestDetailsResultEntryBatchWatcher)
    ]);
}
