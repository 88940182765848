// third-party
import { createSlice, createSelector } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const dayjs = require('dayjs');

const initialState = {
    page: 0,
    rowsPerPage: 10,
    status: 0,
    labTestsList: [],
    labTestDetails: [],
    labTestDetail: {},
    isLoading: false,
    isLoadingValidate: false,
    message: '',
    validationData: {},
    archivedExpiredData: {},
    sampleReport: [],
    dashboadData: {},
    nonConformanceId: null,
    // result entred page
    columnFilter: {
        sampleId: {
            appliedList: [],
            checkedList: []
        },
        products: {
            appliedList: [],
            checkedList: []
        },
        sampleType: {
            appliedList: [],
            checkedList: []
        },
        testType: {
            appliedList: [],
            checkedList: []
        },
        batch: {
            appliedList: [],
            checkedList: []
        },
        customers: {
            appliedList: [],
            checkedList: []
        },
        status: {
            appliedList: [],
            checkedList: []
        },
        requestedTest: {
            appliedList: [],
            checkedList: []
        },
        testIterationCount: {
            appliedList: [],
            checkedList: []
        },
        analysisTestCount: {
            appliedList: [],
            checkedList: []
        }
    },
    // certificate page
    columnFilterTwo: {
        sampleId: {
            appliedList: [],
            checkedList: []
        },
        products: {
            appliedList: [],
            checkedList: []
        },
        sampleType: {
            appliedList: [],
            checkedList: []
        },
        testType: {
            appliedList: [],
            checkedList: []
        },
        batch: {
            appliedList: [],
            checkedList: []
        },
        customers: {
            appliedList: [],
            checkedList: []
        },
        status: {
            appliedList: [],
            checkedList: []
        },
        requestedTest: {
            appliedList: [],
            checkedList: []
        },
        testIterationCount: {
            appliedList: [],
            checkedList: []
        },
        analysisTestCount: {
            appliedList: [],
            checkedList: []
        },
        authorisation: {
            appliedList: [],
            checkedList: []
        }
    }
};

const slice = createSlice({
    name: 'lab',
    initialState,
    reducers: {
        // Pagination
        setPage(state, action) {
            state.page = action.payload;
        },
        setRowsPerPage(state, action) {
            state.rowsPerPage = action.payload;
        },
        // Pagination
        // table filters
        setColumnCheckedList(state, action) {
            const { columnName, data, status, listType, newValue, columnFilterName = 'columnFilter' } = action.payload;
            if (listType === 'DATE' && data) {
                //
                // data =>  startDate or endDate
                //
                let tempColumnFilterOne = { ...state[columnFilterName] };

                tempColumnFilterOne[columnName].checkedList[0][data] = newValue;
                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else if (status) {
                // status refers to checkbox status
                // new item is checked
                // if a new item is checked it will always enter the checked list
                // data ===> id / name based on filter type
                let tempColumnFilterOne = { ...state[columnFilterName] };
                tempColumnFilterOne[columnName].checkedList.push(data);

                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // if the item is getting unchecked that may be in a part of appliedList or checkedList ( we need to hydrate the checklist for consistency )
                // but need to keep in mind untill apply button is pressed don't remove or add to the appliedList
                // That means if item is in the applied list it must be on the checklist
                // on press of apply Button Checked list's data is copied to the Applied list
                let tempColumnFilterOne = { ...state[columnFilterName] };
                let i = 0;
                // eslint-disable-next-line no-plusplus
                for (i; i < tempColumnFilterOne[columnName].checkedList.length; i++) {
                    if (tempColumnFilterOne[columnName].checkedList[i].id === data.id) {
                        break;
                    }
                }
                tempColumnFilterOne[columnName].checkedList.splice(i, 1);

                state[columnFilterName] = { ...tempColumnFilterOne };

                tempColumnFilterOne = {};
            }
        },
        clearUnsavedCheckList(state, action) {
            const { columnName, listType, columnFilterName = 'columnFilter' } = action.payload;
            if (columnName && listType !== 'DATE') {
                let tempColumnFilterOne = { ...state[columnFilterName] };
                tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                state[columnFilterName] = { ...tempColumnFilterOne };
                tempColumnFilterOne = {};
            } else {
                // eslint-disable-next-line no-lonely-if
                if (columnName) {
                    // DATE list-type
                    let tempColumnFilterOne = { ...state[columnFilterName] };
                    if (tempColumnFilterOne[columnName].appliedList.length > 0) {
                        // make checklist same as applied list on close of menu
                        tempColumnFilterOne[columnName].checkedList = [...tempColumnFilterOne[columnName].appliedList];
                        state[columnFilterName] = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    } else {
                        tempColumnFilterOne[columnName].checkedList = [
                            {
                                startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                                endDate: dayjs(new Date()).format('MM-DD-YYYY')
                            }
                        ];
                        state[columnFilterName] = { ...tempColumnFilterOne };
                        tempColumnFilterOne = {};
                    }
                }
            }
        },
        updateAppliedList(state, action) {
            const { updateType, columnName, listType, columnFilterName = 'columnFilter' } = action.payload;
            let tempColumnFilterOne = { ...state[columnFilterName] };
            if (updateType === 'UPDATE') {
                // new checked list moves to the applied list
                // need to avoid dublicate values
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [
                        {
                            startDate: tempColumnFilterOne[columnName].checkedList[0].startDate,
                            endDate: tempColumnFilterOne[columnName].checkedList[0].endDate
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList.forEach((item) => {
                        //  if (!tempExistingAppliedListObj[item.id])
                        tempColumnFilterOne[columnName].appliedList.push(item);
                    });
                }

                state[columnFilterName] = { ...tempColumnFilterOne };
                // tempExistingAppliedListObj = {};
            } else if (updateType === 'CLEAR') {
                if (listType === 'DATE') {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [
                        {
                            startDate: dayjs(new Date(new Date() - 24 * 60 * 60 * 1000)).format('MM-DD-YYYY'),
                            endDate: dayjs(new Date()).format('MM-DD-YYYY')
                        }
                    ];
                } else {
                    tempColumnFilterOne[columnName].appliedList = [];
                    tempColumnFilterOne[columnName].checkedList = [];
                }
            }
            tempColumnFilterOne = {};
        },
        // table filters
        // Non Confermance Id For Edit
        setNonConformanceId(state, action) {
            state.nonConformanceId = action.payload;
        },
        //

        // Filter
        startLabTestDataByFilter(state, action) {
            state.isLoading = true;
        },
        // Lab Test Deatils Filter
        startLabTestDetailsDataByFilter(state, action) {
            state.isLoading = true;
        },
        // Status
        setListStatus(state, action) {
            state.status = action.payload;
        },
        // Status
        startLabTestsList(state, action) {
            state.isLoading = true;
        },
        // scheduledd list (Test Type)
        startLabTestsScheduledList(state, action) {
            state.isLoading = true;
        },
        startResultEnteredOrGeneratedLabTestList(state, action) {
            state.isLoading = true;
        },
        setLabTestsList(state, action) {
            state.labTestsList = action.payload;
        },
        startLabTestDetailByTestRequestId(state, action) {
            state.isLoading = true;
        },
        setLabTestDetail(state, action) {
            state.labTestDetail = action.payload;
        },
        startLabTestDetailsByLabTesttId(state, action) {
            state.isLoading = true;
        },
        setLabTestDetails(state, action) {
            state.labTestDetails = action.payload;
        },
        startLabTestStatusUpdate(state, action) {
            state.isLoading = true;
        },
        startAddUpdateLabTest(state, action) {
            state.isLoading = true;
        },
        // Report Sample Log
        startLabSampleLogReport(state, action) {
            state.isLoading = true;
        },
        setLabSampleLog(state, action) {
            state.sampleReport = action.payload;
        },
        /// Validation
        startValidationCheck(state, action) {
            state.isLoadingValidate = true;
        },
        startAllToBeValidatedList(state, action) {
            state.isLoading = true;
        },
        // Non-Conformance
        startAddNonConfermance(state, action) {
            state.isLoading = true;
        },
        completedAction(state, action) {
            state.isLoading = false;
            state.isLoadingValidate = false;
        },
        /// Lab Test Details Table
        startAddLabTestDetailsResult(state, action) {
            state.isLoading = true;
        },
        /// State Data
        setValidationData(state, action) {
            state.validationData = action.payload;
        },
        /// Dashboards View
        startDashboardsData(state, action) {
            state.isLoading = true;
        },
        setDashboardsData(state, action) {
            state.dashboadData = action.payload;
        },
        /// Archive And Disposal
        startArchiveAndDisposalAddData(state, action) {
            state.isLoading = true;
        },
        // Get Archive Data
        startArchivedDataByLabId(state, action) {
            state.isLoading = true;
        },
        /// archived Data Data
        setArchivedData(state, action) {
            state.archivedExpiredData = action.payload;
        },
        // Expired / Disposal List
        startArchivedExpiredLabTestSample(state, action) {
            state.isLoading = true;
        },
        //  Archive And Disposed Details by Id
        startArchivedExpiredSpecimensDetailsById(state, action) {
            state.isLoading = true;
        },
        // Result Entry in Batch
        startResultEntryInBatchGoupedByAnalysis(state, action) {
            state.isLoading = true;
        }
    }
});
// Actions Exports
export const {
    setNonConformanceId,
    setListStatus,
    setPage,
    setRowsPerPage,
    // filters
    setColumnCheckedList,
    clearUnsavedCheckList,
    updateAppliedList,
    ///
    startLabTestDataByFilter,
    startLabTestDetailsDataByFilter,
    startAllToBeValidatedList,
    startLabTestsList,
    startLabTestsScheduledList,
    startResultEnteredOrGeneratedLabTestList,
    setLabTestsList,
    startLabTestDetailByTestRequestId,
    setLabTestDetail,
    startLabTestDetailsByLabTesttId,
    setLabTestDetails,
    startAddUpdateLabTest,
    startLabSampleLogReport,
    setLabSampleLog,
    startValidationCheck,
    startLabTestStatusUpdate,
    startAddNonConfermance,
    completedAction,
    startAddLabTestDetailsResult,
    setValidationData,
    startDashboardsData,
    setDashboardsData,
    startArchiveAndDisposalAddData,
    startArchivedDataByLabId,
    setArchivedData,
    startArchivedExpiredLabTestSample,
    startResultEntryInBatchGoupedByAnalysis
} = slice.actions;
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// selectors

const labStates = (state) => state.lab;
/// Data Loading Selector
export const isLoadingSelector = createSelector([labStates], (labDataStates) => labDataStates.isLoading);

// isLoadingValidate
export const isLoadingValidateSelector = createSelector([labStates], (labDataStates) => labDataStates.isLoadingValidate);
// lab_Tests_list
export const labTestsSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestsList);
// lab-test-data
export const labTestDetailsSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestDetail);
// for sample-splitting
export const validationDataSelector = createSelector([labStates], (labDataStates) => labDataStates.validationData);
// For Scheduled test result entry Lab Test Details
export const labTestDetailsByLabIdSelector = createSelector([labStates], (labDataStates) => labDataStates.labTestDetails);
// Report Sample Log
export const labTestSampleReportSelector = createSelector([labStates], (labDataStates) => labDataStates.sampleReport);
// Dashboard Data
export const dashboardDataSelector = createSelector([labStates], (labDataStates) => labDataStates.dashboadData);
// Archived Data
export const archivedDataSelector = createSelector([labStates], (labDataStates) => labDataStates.archivedExpiredData);
// Page Selector
export const pageSelector = createSelector([labStates], (labDataStates) => labDataStates.page);
// Row Per Page Selector
export const rowsPerPageSelector = createSelector([labStates], (labDataStates) => labDataStates.rowsPerPage);
//
export const statusSelector = createSelector([labStates], (labDataStates) => labDataStates.status);
// Non Conformance Id
// nonConformanceId
export const nonConformanceIdSelector = createSelector([labStates], (labDataStates) => labDataStates.nonConformanceId);
//
export const columnFilterSelector = createSelector([labStates], (labDataStates) => labDataStates.columnFilter);

export const columnFilterTwoSelector = createSelector([labStates], (labDataStates) => labDataStates.columnFilterTwo);
