import { takeLatest, all, call, put } from 'redux-saga/effects';

import {
    startAddingNewTest,
    startAllTestRequest,
    setTestRequest,
    startDeleteTestByID,
    startTestRequestData,
    setTestRequestData,
    startTestRequestDataByFilter,
    startTestRequestDetailsByTestRequestId,
    setTestRequestDetailsByTestRequestId,
    startAddMoreTestReqDetail,
    startUpdateIterationOnTestReq,
    completedAction,
    startTestRequestStatusUpdate,
    startUpdateCustomer,
    startUpdateTestRequestData
} from './testRequests';
import {
    getAllTestRequest,
    addNewTest,
    getTestRequestData,
    deleteById,
    testRequestFilter,
    testReqStatusUpdate,
    getTestRequestsByPagination,
    updateCustomer
} from 'utils/apis/testRequest';
import {
    getTestRequestDetailsByTestRequestId,
    addMoreTestReq,
    updateIterationTestReq,
    updateTestReqDetail
} from 'utils/apis/testRequestDetail';
import { openSnackbar } from '../snackbar';
import { addSpecimenValidation } from 'utils/apis/validate-specimen';
import { labTestStatusUpdate } from 'utils/apis/lab-test';
import { startLabTestsList } from '../lab/lab';
import { setFormNavigate } from '../formNavigate/formNavigateSlice';

// Add  New Test  Response (1)
function* addingResponseHandler({ payload }) {
    try {
        yield call(addNewTest, payload);
        yield put(startAllTestRequest());
        yield put(setFormNavigate(true));
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Test Requested Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Add New Test Action watcher(1)
function* startAddingNewTestrFetchWatcher() {
    yield takeLatest(startAddingNewTest.type, addingResponseHandler);
}

// Test Requests Response (2)
function* testRequestResponseHandler({ payload }) {
    try {
        const response = yield call(getTestRequestsByPagination, payload);
        yield put(setTestRequest(response));
        yield put(completedAction());
    } catch (error) {
        console.log(error?.response?.status, 'finding Error');
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Requests Watcher (2)
function* startTestRequestsFetchWatcher() {
    yield takeLatest(startAllTestRequest.type, testRequestResponseHandler);
}

// Delete Test Requests Response (3)
function* deleteCustomersResponseHandler({ payload }) {
    try {
        yield call(deleteById, payload);
        yield put(startAllTestRequest());
        yield put(completedAction());
        yield put(
            openSnackbar({
                open: true,
                message: 'Deleted Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Delete Test Requests  Watcher (3)
function* startDeleteCustomerWatcher() {
    yield takeLatest(startDeleteTestByID.type, deleteCustomersResponseHandler);
}

// Test Requests Data by Id  Response (4)
function* testRequestDataByIDResponseHandler({ payload }) {
    try {
        const response = yield call(getTestRequestData, payload);
        yield put(setTestRequestData(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Test  Requests Data by Id  Watcher (4)
function* startTestRequestDataByIDdWatcher() {
    yield takeLatest(startTestRequestData.type, testRequestDataByIDResponseHandler);
}

// Test Requests Data by Filter (5)
function* testRequestDataByFilterResponseHandler({ payload }) {
    try {
        const response = yield call(testRequestFilter, payload);
        yield put(setTestRequest(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Test  Requests Data by Filter  Watcher (5)
function* startTestRequestDataByFilterdWatcher() {
    yield takeLatest(startTestRequestDataByFilter.type, testRequestDataByFilterResponseHandler);
}
// Test Requests Status Update  Response (6)
function* testReqStatusUpdateResponseHandler({ payload }) {
    try {
        yield call(testReqStatusUpdate, payload);
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Requests status Update  (6)
function* startTestReqStatusUpdatehWatcher() {
    yield takeLatest(startTestRequestStatusUpdate.type, testReqStatusUpdateResponseHandler);
}

// Test Requests Customer Update  Response (7)
function* testReqCustomerUpdateResponseHandler({ payload }) {
    try {
        const { id } = payload;
        // alert(JSON.stringify(payload));
        const response = yield call(updateCustomer, payload);
        yield put(startTestRequestData(id));
        yield put(
            openSnackbar({
                open: true,
                message: response.data.message,
                variant: 'alert',
                alert: {
                    color: response.status === 200 ? 'success' : 'error'
                },
                close: false,
                actionButton: false
            })
        );

        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Test Requests Customer Update  (7)
function* startUpdateRequestCustomerByTestIdWatcher() {
    yield takeLatest(startUpdateCustomer.type, testReqCustomerUpdateResponseHandler);
}
//  /////////////////////////////////////// --Test Request Details Table--- //////////////////////////////////////////////////////////////////////

// Test Requests Details By Test-Request-Id  Response (1)
function* testRequestDetailsByTestRequestIdResponseHandler({ payload }) {
    try {
        yield put(setTestRequestDetailsByTestRequestId([]));
        const response = yield call(getTestRequestDetailsByTestRequestId, payload);
        yield put(setTestRequestDetailsByTestRequestId(response));
        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Test Requests Details By Test-Request-Id Watcher (1)
function* startTestRequestDetailsByTestRequestIdWatcher() {
    yield takeLatest(startTestRequestDetailsByTestRequestId.type, testRequestDetailsByTestRequestIdResponseHandler);
}
// Add More Tests  Requests Details  Response (2)
function* addMoreTestResponseHandler({ payload }) {
    try {
        yield call(addMoreTestReq, payload);
        // const { test_request_id: testReqId } = payload;
        yield put(startTestRequestDetailsByTestRequestId(payload.data.test_request_id));
        yield put(
            openSnackbar({
                open: true,
                message: 'Tests Added Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
// Add More Tests  Requests Details Watcher (2)
function* startAddMoreTestReqDetailTestRequestIdWatcher() {
    yield takeLatest(startAddMoreTestReqDetail.type, addMoreTestResponseHandler);
}
//   Update Itertions  Response (3)
function* updateIterationTestReqResponseHandler({ payload }) {
    const { updateData, validationData } = payload;
    try {
        yield call(updateIterationTestReq, { updateData, validationData });
        yield put(setFormNavigate(true));
        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );

        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Update Itertions  Tests  Requests Details Watcher (3)
function* startupdateIterationTestReqdWatcher() {
    yield takeLatest(startUpdateIterationOnTestReq.type, updateIterationTestReqResponseHandler);
}

//   Update Test Detail Data  Response (4)
function* updateTestReqResponseHandler({ payload }) {
    try {
        yield call(updateTestReqDetail, payload);
        yield put(setFormNavigate(true));
        yield put(
            openSnackbar({
                open: true,
                message: 'Updated Successfully',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false,
                actionButton: false
            })
        );

        yield put(completedAction());
    } catch (error) {
        yield put(
            openSnackbar({
                open: true,
                message: error.message,
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                actionButton: false
            })
        );
        yield put(completedAction());
    }
}
//  Update Test Detail Data Watcher (4)
function* startupdateTestReqdWatcher() {
    yield takeLatest(startUpdateTestRequestData.type, updateTestReqResponseHandler);
}

// Accumulator
export default function* testRequestDataSaga() {
    yield all([
        call(startAddingNewTestrFetchWatcher),
        call(startTestRequestsFetchWatcher),
        call(startDeleteCustomerWatcher),
        call(startTestRequestDetailsByTestRequestIdWatcher),
        call(startTestRequestDataByIDdWatcher),
        call(startAddMoreTestReqDetailTestRequestIdWatcher),
        call(startupdateIterationTestReqdWatcher),
        call(startTestRequestDataByFilterdWatcher),
        call(startTestReqStatusUpdatehWatcher),
        call(startupdateTestReqdWatcher),
        call(startUpdateRequestCustomerByTestIdWatcher)
    ]);
}
